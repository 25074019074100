<template>
  <div class="index_container agreement">
    <ItemScroll>
      <div class="agreement_main">
        <p
          style="
            text-align: center;
            font-size: 14px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 16px; color: #333333"
              >骨转百讯用户使用协议</span
            ></strong
          >
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          <strong
            ><span style="color: #333333"
              >您在开始使用骨转百讯服务前，请您务必仔细阅读并充分理解本《骨转百讯用户使用协议》。同时，您还应仔细阅读并充分理解骨转百讯的隐私政策、知识产权申请等系列政策和声明。在本服务条款中，请您务必特别注意及重点阅读与您的权利及义务密切相关的条款，此类条款可能以粗体加黑的方式标记，包括但不限于免责条款等。</span
            ></strong
          >
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="color: #333333">一、使用协议的接受</span></strong
          >
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
        >
          <li>
            <p>
              <span
                >为使您更好的使用骨转百讯的相关服务，请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入<strong>骨转百讯</strong>。当您登陆成功，无论是进入骨转百讯，还是在骨转百讯上发布任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。</span
              >
            </p>
          </li>
          <li>
            <p>
              <strong><span>如您未满</span></strong
              ><strong><span>14</span></strong
              ><strong
                ><span
                  >周岁，请在法定监护人的陪同下阅读本用户使用协议，并特别注意未成年人使用的相关条款。</span
                ></strong
              >
            </p>
          </li>
          <li>
            <p>
              <span>“</span><span>用户</span><span>”</span
              ><span
                >指所有直接或间接获取和使用骨转百讯及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为</span
              ><span>“</span><span>用户</span><span>”</span><span>或称</span
              ><span>“</span><span>您</span><span>”</span><span>。</span>
            </p>
          </li>
          <li>
            <p>
              <span>本协议是您与</span>
              <span>中关村精准医学基金会</span>
              <span>（以下简称</span><span>“</span><span>公司</span
              ><span>”</span><span>或</span><span>“</span><span>我们</span
              ><span>”</span><span>）之间就您登录、使用</span><span>“</span
              ><span>骨转百讯</span><span>”</span
              ><span
                >网页，并获得骨转百讯提供的相关服务所订立的协议。公司有权依骨转百讯及相关服务或运营的需要单方决定，安排或指定其关联公司、控制公司、继承公司或公司认可的第三方公司</span
              ><strong>【上海医飞科技有限公司】（以下简称“第三方”）</strong
              ><span>继续运营骨转百讯平台。</span
              ><strong
                >第三方【上海医飞科技有限公司】负责本次【骨转百讯】平台搭建及具体执行。同时，我们也会严格督促与第三方的合作信息。</strong
              ><span
                >并且，就本协议项下涉及的某些服务，可能会由公司的关联公司、控制公司、继承公司或公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="color: #333333">二、骨转百讯提供的服务</span></strong
          >
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >骨转百讯不断创新以向其用户提供最优体验。您认知并同意骨转百讯提供的服务的形式和本质可不经事先通知您而不时变换。本软件及相关服务更新或部分服务内容更新后，在可能的情况下，公司将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯及相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司合法运营的其他单项服务。这些服务在骨转百讯平台可能以单独板块形式存在。公司有权不时地增加、减少或改动这些特别板块的设置及服务。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >作为这种持续创新的一部分，您认知并同意骨转百讯可自行决定，无须事先通知您，即停止（永久或暂时）向您或全体用户提供服务。您可在任何时候停止使用服务。您停止使用服务时无需特别通知骨转百讯。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您认知并同意，如果骨转百讯禁用对您的帐户的访问权，则您可能被阻止获得服务、您的帐户资料或包含在您帐户中的任何文件或其他内容。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您认知并同意，尽管骨转百讯可能目前没有设置您可通过服务发送或接收的传输数量或用于提供任何服务的存储空间的上限，但骨转百讯可自行决定在任何时候设置上限。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >为保证骨转百讯软件及相关服务安全、提升用户服务，您使用骨转百讯及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等装置），一旦您在其终端设备中打开骨转百讯或访问骨转百讯平台，即视为您使用骨转百讯及相关服务。为充分实现骨转百讯平台的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您理解并同意部分服务由广告收入支持，可展示广告和推销。这些广告可能是针对存储于服务中的信息、通过服务提出的询问或其他信息的内容提供的。骨转百讯在服务上的广告的方式、模式和范围可不经向您特别通知而变更。作为骨转百讯授予您访问和使用服务的权利的对价，您同意骨转百讯可以在服务上加载该等广告。当然您有权根据《骨转百讯隐私政策》条款的指引进行相应的推送关闭。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">三、平台使用规则</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >骨转百讯将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >用户须对在骨转百讯的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则骨转百讯有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>用户直接或通过各类方式（如</span><span> RSS </span
              ><span>源和站外</span><span> AdivI </span
              ><span
                >引用等）间接使用骨转百讯服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用骨转百讯所提供的全部服务。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯是一个医疗健康相关信息提供、分享、传播及获取的平台，用户通过骨转百讯发表的信息为公开的信息，其他第三方均可以通过骨转百讯获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在骨转百讯上进行发表。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >用户承诺不得以任何方式利用骨转百讯直接或间接从事违反中国法律以及社会公德的行为，骨转百讯有权对违反上述承诺的内容予以删除。</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span
                  >用户不得利用骨转百讯服务制作、上载、复制、发布、传播或者转载如下内容：</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>反对宪法所确定的基本原则的；</span>
              </p>
            </li>
            <li>
              <p>
                <span
                  >危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>损害国家荣誉和利益的；</span>
                <span>煽动民族仇恨、民族歧视，破坏民族团结的；</span>
              </p>
            </li>
            <li>
              <p>
                <span
                  >侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
              </p>
            </li>
            <li>
              <p>
                <span>散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
              </p>
            </li>
            <li>
              <p>
                <span
                  >散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>侮辱或者诽谤他人，侵害他人合法权益的；</span>
              </p>
            </li>
            <li>
              <p>
                <span>含有法律、行政法规禁止的其他内容的信息。</span>
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >骨转百讯有权对用户使用骨转百讯的情况进行审查和监督，如用户在使用骨转百讯时违反任何上述规定，骨转百讯或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用骨转百讯的权利）以减轻用户不当行为造成的影响。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯保留从任何服务中筛选、审阅、标明、过滤、修订、拒绝或删除任何或所有内容的权利（但无义务这样做）。就某些服务而言，骨转百讯可提供滤除明确色情内容、政治相关内容的工具。此外，还有可以通过商业渠道获得的服务和软件能够限制访问令您反感的材料</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>我们非常注重未成年人的保护：</span>
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>(1) </span
                ><span
                  >若您为未成年人，应在监护人监护、指导下仔细阅读本协议，并且使用我们的产品及</span
                ><span>/</span><span>或服务已经得到监护人的同意；</span>
              </p>
            </li>
            <li>
              <p>
                <span>(2) </span
                ><span
                  >监护人应注意指导子女上网应注意的安全问题，提前做好相应的风险防范指导工作。若监护人同意未成年人使用我们的产品及</span
                ><span>/</span
                ><span
                  >或服务，必须以监护人的名义申请消费（如涉及），并对未成年人使用我们的产品及</span
                ><span>/</span
                ><span
                  >或服务进行正确的引导、监督。未成年人使用我们的产品及</span
                ><span>/</span
                ><span
                  >或服务，以及行使和履行本协议项下的权利义务视为已获得了监护人的认可；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(3) </span><span>我们提醒未成年人在使用我们产品及</span
                ><span>/</span
                ><span
                  >或服务时，要善于网上学习，避免沉迷网络，影响日常学习生活。</span
                >
              </p>
            </li>
          </ul>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">四、账户安全</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >您同意并理解您有责任将您与用于获得服务的任何帐户相关的密码保密。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>您同意将独自就在您帐户下的所有活动对骨转百讯负责。</span>
            </p>
          </li>
          <li>
            <p>
              <span
                >如果您得知任何对您的密码或您的帐户的任何未经授权的使用，您同意立即通知骨转百讯。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >关于骨转百讯的数据保护惯例的信息，请查阅骨转百讯的《骨转百讯隐私政策》。该政策解释了骨转百讯如何处理您的个人信息，并在您使用服务时保护您的隐私。您同意按照骨转百讯的隐私政策使用您的数据。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">五、知识产权</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >骨转百讯是一个信息获取、分享及传播的平台，我们尊重和鼓励骨转百讯用户创作发表分享的内容，骨转百讯充分认知到保护知识产权对骨转百讯生存与发展的重要性，承诺将保护知识产权作为骨转百讯运营的基本原则之一。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >用户在骨转百讯上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权和版权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到骨转百讯的同意。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯上可由多人参与编辑的内容，包括但不限于问题及补充说明、答案总结、话题描述、话题结构，所有参与编辑者均同意，相关知识产权归所有参与编辑者所有。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人（含骨转百讯及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >为了促进知识的分享和传播，用户将其在骨转百讯上发表的全部内容，授予骨转百讯免费的、不可撤销的、非独家使用和传播的许可，骨转百讯有权将该内容用于骨转百讯各种形态的产品和服务上，包括但不限于平台以及发表的应用或其他互联网产品。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >第三方若出于非商业目的，将用户在骨转百讯上发表的内容转载在骨转百讯之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在骨转百讯上使用的帐号名称），给出原始链接，注明「发表于骨转百讯」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯为用户提供「保留所有权利，禁止转载」的选项。除非获得原作者的单独授权，任何第三方不得转载标注了「禁止转载」的内容，否则均视为侵权。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >在骨转百讯上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，骨转百讯有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给骨转百讯或任何第三方造成损失的，用户应负责全额赔偿。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >如果任何第三方侵犯了骨转百讯用户相关的权利，用户同意授权骨转百讯或其指定的代理人代表骨转百讯自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在骨转百讯认为必要的情况下参与共同维权。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及骨转百讯原则对侵权信息进行处理。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您认知并同意，骨转百讯（或骨转百讯的许可方）对服务拥有一切法定权利、所有权和利益，包括存在于服务中的任何知识产权（无论该等权利是否已经登记，也不论该等权利在世界的何等地方存在）。您进一步认知，服务可能包括骨转百讯指定为保密的信息，未经骨转百讯事先书面同意，您不得披露该等信息。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >除非您与骨转百讯另有书面协议，否则本条款中的任何规定均未给予您使用骨转百讯任何商号、商标、服务标记、标识、域名及其他显著品牌特征的权利。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >如果您在与骨转百讯的单独书面协议中被给予一项使用上述品牌特征的明确的权利，则您同意您在使用该等品牌特征时遵守该协议、本条款的任何适用规定以及不时更新的骨转百讯品牌特征使用指南。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>除第</span><span>11</span
              ><span
                >条规定的限制许可外，骨转百讯认知并同意，其不在本条款项下获得您（或您的许可方）对在服务上或通过服务提交、张贴、传输或展示的任何内容的任何权利、所有权或利益，包括该内容中存在的任何知识产权（无论该等权利是否已经登记，亦不论该等权利在世界的何等地方存在）。除非您与骨转百讯另有书面协议，否则您同意您负责保护并强制执行这些权利，骨转百讯没有义务代表您这样做。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您同意您不得删除、掩藏或改动服务所附的或包含的任何专有权利声明（包括著作权和商标声明）。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >除非您得到骨转百讯书面明确授权这样做，否则您同意在使用服务时，您将不以可能或故意导致混淆该等商标、名称或标识的所有者或授权用户的方式使用任何公司或组织的商标、服务标识、商号、标识。</span
              ><span>&nbsp;</span>
            </p>
          </li>
        </ol>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">六、侵权举报</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >骨转百讯高度重视自由表达和个人、企业正当权利的平衡。依照法律规定删除违法信息是骨转百讯社区的法定义务，骨转百讯社区亦未与任何中介机构合作开展此项业务。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>（</span><span>1</span
                ><span
                  >）涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>2</span
                ><span
                  >）造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>3</span
                ><span
                  >）商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。</span
                >
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >用户在骨转百讯发表的内容仅表明其个人的立场和观点，并不代表骨转百讯的立场或观点。如果个人或企业发现骨转百讯上存在侵犯自身合法权益的内容，可以先尝试与作者取得联系，通过沟通协商解决问题。如您无法联系到作者，或无法通过与作者沟通解决问题，您可通过向骨转百讯指定邮箱发送侵权投诉内容至邮箱</span
              ><span>yifeikeji@medflying.com</span
              ><span
                >进行投诉。为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则投诉将无法受理。您需要向骨转百讯提供的投诉材料包括：</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>（</span><span>1</span
                ><span>）权利人对涉嫌侵权内容拥有商标权、著作权和</span
                ><span>/</span
                ><span
                  >或其他依法可以行使权利的权属证明，权属证明通常是营业执照或组织机构代码证；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>2</span
                ><span>）举报人的身份证明，身份证明可以是身份证或护照；</span>
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>3</span
                ><span
                  >）如果举报人非权利人，请举报人提供代表权利人进行举报的书面授权证明。</span
                >
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >为确保投诉材料的真实性，在侵权举报中，您还需要出具以下法律声明：</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>（</span><span>1</span
                ><span>）我本人为所举报内容的合法权利人；</span>
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>2</span
                ><span
                  >）我举报的发布在骨转百讯社区中的内容侵犯了本人相应的合法权益；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>3</span
                ><span
                  >）如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任，并承担和赔偿骨转百讯因根据投诉人的通知书对相关帐号的处理而造成的任何损失，包括但不限于骨转百讯因向被投诉方赔偿而产生的损失及骨转百讯名誉、商誉损害等。</span
                >
              </p>
            </li>
          </ul>
          <li>
            <p><span>处理流程</span></p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>（</span><span>1</span
                ><span
                  >）出于网络平台的监督属性，并非所有申请都必须受理。骨转百讯自收到举报的七个工作日内处理。处理期间，不提供任何电话、邮件及其他方式的查询服务。</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>2</span
                ><span
                  >）出现骨转百讯已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题骨转百讯无权也无法处理，因此相关申请不予受理。您可以自行联系搜索引擎服务商进行处理。</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>3</span
                ><span
                  >）此为骨转百讯唯一的官方侵权投诉渠道，暂不提供其他方式处理此业务。</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>（</span><span>4</span
                ><span
                  >）用户在骨转百讯中的商业行为引发的法律纠纷，由交易双方自行处理，与骨转百讯无关。</span
                >
              </p>
            </li>
          </ul>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="color: #333333"
              >七、终止您与骨转百讯的关系</span
            ></strong
          >
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >如果您希望终止与使用骨转百讯产品及服务的，您可以通过【骨转百讯隐私政策】中规定的方式注销账号及相关使用信息。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >发生下列情况时骨转百讯可单方终止其与您的服务关系及相关协议约定：</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>(A)</span
                ><span
                  >您违反了本条款的任何规定（或您的行为方式明确显示您不打算或不能遵守本条款规定）；或</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(B)</span
                ><span
                  >法律要求骨转百讯这样做（例如：向您提供服务不合法或变得不合法）；或</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(C) </span
                ><span
                  >骨转百讯与之一起向您提供服务的合作伙伴已终止与骨转百讯的关系或停止向您提供服务；或</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(D) </span
                ><span
                  >骨转百讯转变为不再向您居住的或您从那里使用服务的国家内的用户提供服务；或</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(E) </span
                ><span
                  >骨转百讯认为，骨转百讯向您提供服务不再具有商业可行性。</span
                >
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >本条款终止时，您和骨转百讯已经享受或承担的（或在本条款有效期间已经产生的）或明确规定为无限期有效的所有法定权利、义务和责任不受该终止的影响</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯、其子公司和关联公司及其许可人，不就以下各项向您作出陈述或保证：</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p><span>(A)</span><span>您对服务的使用将符合您的需求；</span></p>
            </li>
            <li>
              <p>
                <span>(B)</span
                ><span>您对服务的使用将无中断、及时、安全或没有错误；</span>
              </p>
            </li>
            <li>
              <p>
                <span>(C)</span
                ><span
                  >由于您使用服务而获得的任何信息将是准确的或可靠的；及</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(D)</span
                ><span
                  >作为服务的一部分向您提供的任何软件的运行或功能中的缺陷将被纠正。</span
                >
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >通过使用服务而下载或以其他方式获得的任何材料由您自行作出并承担风险，您将独自对由于下载任何该等材料而导致对电脑系统或其他装置的损害或数据的丢失负责。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您从骨转百讯获得的或通过服务或从服务获得的任何建议或信息（无论口头还是书面的）均不创立本条款中未明确规定的任何保证。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯进一步明确否认任何种类的所有保证和条件（无论明示还是默示的），包括但不限于适销性、适合特定目的及不侵权的默示保证和条件。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">八、责任限制</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >在遵守本协议全部规定的前提下，您明示理解并同意，骨转百讯、其子公司和关联公司及其许可人不就以下事项对您承担责任：</span
              >
            </p>
          </li>
          <ul type="circle" style="margin-bottom: 0" class="list-paddingleft-2">
            <li>
              <p>
                <span>(A)</span
                ><span
                  >您无论由于何种原因和在任何责任理论项下发生的任何直接、间接、附带、特殊、后果性或惩罚性的损害。这应包括但不限于任何利润损失（无论是直接还是间接发生）、任何商誉或业务声誉损失、任何数据丢失、替代物品或服务的购买费用或其他无形损失；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(B)</span
                ><span
                  >您可能产生的任何损失或损害，包括但不限于由下列原因导致的损失或损害：</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(C)</span
                ><span>您对任何广告的完整性、准确性或其存在的信任，</span>
                <span
                  >或作为您与其广告出现在服务中的任何广告商或赞助人之间的任何关系或交易的结果；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(D) </span
                ><span
                  >骨转百讯对服务可能做出的变更，或永久或暂时停止提供服务（或服务中的任何功能）；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(E)</span
                ><span
                  >对通过您使用服务而维持或传输的任何内容及其他通信数据的删除、毁坏或未能将其储存；</span
                >
              </p>
            </li>
            <li>
              <p>
                <span>(F)</span><span>您未向骨转百讯提供准确的帐户信息；</span>
              </p>
            </li>
            <li>
              <p>
                <span>(G)</span
                ><span>您未对您的密码或帐户资料保持安全及保密；</span>
              </p>
            </li>
          </ul>
          <li>
            <p>
              <span
                >无论骨转百讯是否接到通知或是否应已知晓引起任何该等损失的可能性，上文第</span
              ><span>1</span><span>款中骨转百讯对您的责任限制均应适用。</span>
            </p>
          </li>
          <li>
            <p>
              <span
                >对关于符合适用国际知识产权法（包括中国的《著作权法》）的指称的著作权侵权通知做出回应以及终止重复侵权者帐户是骨转百讯的政策。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>骨转百讯不能对用户发表的回答或评论的正确性进行保证。</span>
            </p>
          </li>
          <li>
            <p>
              <span
                >用户在骨转百讯发表的内容仅表明其个人的立场和观点，并不代表骨转百讯的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。骨转百讯不承担任何法律及连带责任。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >骨转百讯不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >对于因不可抗力或骨转百讯不能控制的原因造成的网络服务中断或其它缺陷，骨转百讯不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">九、其他内容</span></strong>
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >服务可包含对其他平台或内容或资源的超级链接。骨转百讯可能并不控制由骨转百讯以外的公司或个人提供的任何平台或资源。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您认知并同意，骨转百讯不对该等外部平台或资源的可用性负责，亦不对该等网络或资源上的或从该等平台或资源获得的任何广告、产品或其他材料加以认可。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您认知并同意，骨转百讯不对由于您由于那些外部的平台或资源的可用性或您对该等平台或资源上的或从该等平台或资源获得的任何广告、产品或其他材料的完整性、准确性或存在的信赖而发生的任何损失或损害承担责任。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span style="color: #333333">十、免责声明</span></strong
          ><span style="color: #333333"><br /></span
          ><span style="color: #333333"
            >「骨转百讯」中的所有内容，只作为供医疗行业专业用户参考，但不作为诊断、确诊和治疗依据，也不成为任何享有法律效力的书面证明文件，不能代替医生面诊和医院诊断。若用户以此为诊断和治疗依据，由此可能引发的任何负面影响甚至损失由用户自行负责，「骨转百讯」平台不予负责。</span
          >
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="color: #333333">十一、本协议的变更</span></strong
          >
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >骨转百讯可不时对通用条款或附加条款作出变更，不再另行通知。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >您理解并同意，如果您在通用条款或附加条款变更日期之后使用服务，则骨转百讯将把您的使用视为接受更新后的通用条款或附加条款。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="color: #333333">十二、一般法律条款</span></strong
          >
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: medium; text-wrap: wrap"
          class="list-paddingleft-2"
        >
          <li>
            <p>
              <span
                >本协议及本协议条款项下您与骨转百讯的关系，受中国法律管辖，但排除其冲突法规定。您与骨转百讯均同意接受位于中国境内的【</span
              ><span style="color: windowtext">中关村精准医学基金会</span
              ><span
                >】所在地【北京】有管辖权的人民法院的管辖，以解决任何由本条款引起的法律事项。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>本协议内容中的条款，请您着重阅读。您点击</span><span>“</span
              ><span>同意</span><span>”</span
              ><span
                >按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</span
              >
            </p>
          </li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          <span style="color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            text-align: right;
            font-size: 14px;

            text-wrap: wrap;
          "
        >
          中关村精准医学基金会
        </p>
        <p>
          <span
            style="
              text-wrap: wrap;
              font-size: 14px;

              color: #333333;
            "
            >2023年11月25日</span
          >
        </p>
        <p><br /></p></div
    ></ItemScroll>
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "userAgreement",
  components: {
    ItemScroll,
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
  padding: 4vw;
}
.agreement_main {
  height: 100%;
}
.agreement ol li {
  margin-left: 40px;
}
.agreement p {
  margin: 1rem 0;
}
</style>
